<template>
  <ContentHeader
    v-bind:title="content_header.title"
    v-bind:subTitle="content_header.subTitle"
  />
</template>

<script>
// import KTAside from "@/view/layout/aside/Aside.vue";
export default {
  name: "dashboard",
  components: {
    // KTAside
  },
  data: function () {
    return {
      content_header: {
        title: "Welcome to  Core Lending  Management System",
        subTitle:
          "Features/Sections will be released in batches as they are completed and pass UAT",
      },
    };
  },
};
</script>
